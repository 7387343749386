export const convertOldNamesToNewPlan = (oldName: string) => {
  const mapping: any = {
    basic: "subscriber",
    plus: "patron",
    premium: "premium",
  };

  // Return the mapped value or the original oldName if no match
  return mapping[oldName] || oldName;
};

export const convertNewPlanToOldName = (newName: string) => {
  const mapping: any = {
    subscriber: "basic",
    patron: "plus",
    premium: "premium",
  };

  // Return the mapped value or the original newName if no match
  return mapping[newName] || newName;
};

export const planNameToInteger = (planName: string) => {
  const mapping: any = {
    subscriber: 1,
    patron: 2,
    premium: 3,
  };

  return mapping[planName] || 0;
};
